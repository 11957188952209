import { appConfig } from "./../../app.config";
import { Injectable } from "@angular/core";
import { ResourceService } from "../resource/resource.service";
import { Observable, Subject } from "rxjs";
import { HttpClient } from "@angular/common/http";

@Injectable({
  providedIn: "root",
})
export class MediaService {
  private startDate: string;
  private endDate: string;
  constructor(
    public resourceService: ResourceService,
    public http: HttpClient
  ) {}

  panel = new Subject();
  panel$ = this.panel.asObservable();

  sendUpdate = new Subject();
  sendUpdate$ = this.sendUpdate.asObservable();

  sendUpdateToCreate(state) {
    this.sendUpdate.next(state);
  }

  updatePanel(state) {
    this.panel.next(state);
  }

  sendMediaToNotification = new Subject();
  sendMediaToNotification$ = this.sendMediaToNotification.asObservable();

  sendMediaToPushNotification(data) {
    this.sendMediaToNotification.next(data);
  }

  attachNotification = new Subject();
  attachNotification$ = this.attachNotification.asObservable();

  attachMediaNotifiaction(data) {
    this.attachNotification.next(data);
  }

  embedPanel = new Subject();
  embedPanel$ = this.embedPanel.asObservable();

  openEmbedPanel(data) {
    this.embedPanel.next(data);
  }

  mostRecentMediaEmbed = new Subject();
  mostRecentMediaEmbed$ = this.mostRecentMediaEmbed.asObservable();

  openMostRecentMediaEmbed(data) {
    this.mostRecentMediaEmbed.next(data);
  }

  eventEmbed = new Subject();
  eventEmbed$ = this.eventEmbed.asObservable();

  openEventEmbed(data) {
    this.eventEmbed.next(data);
  }

  mobileEmbed = new Subject();
  mobileEmbed$ = this.mobileEmbed.asObservable();

  openMobileEmbed(data) {
    this.mobileEmbed.next(data);
  }

  createMediaItem(data) {
    const createMediaItemUrl = `${appConfig.mediaItem}/mediaItem`;
    return this.resourceService.postData(createMediaItemUrl, data);
  }

  createChannelMediaItem(data, type) {
    let createMediaItemUrl;
    if (type) {
      createMediaItemUrl = `${appConfig.mediaItem}/mediaItem?channelType=${type}`;
    } else {
      createMediaItemUrl = `${appConfig.mediaItem}/mediaItem`;
    }
    return this.resourceService.postData(createMediaItemUrl, data);
  }

  getItemAnalytics(id:any,page:any,size:any){
    const urlAnalytics = `${appConfig.getAnalyticDetail}/?mediaItemId=${id}&page=${page}&size=${size}`
    return this.resourceService.getData(urlAnalytics);
  }
  
  // http://52.71.217.35:8082/admin-service/api/v1/viewerAnalytics/getCumulativeViewerCount?fromDate=2025-01-01&mediaItemId=19516&toDate=2025-01-10
  getItemAnalyticsCumulative(id:any,fd:any,td:any){
    const urlAnalytics = `${appConfig.getCumulativeTask}?fromDate=${fd}&mediaItemId=${id}&toDate=${td}`
    console.log("getItemAnalyticsCumulative", urlAnalytics)
    return this.resourceService.getData(urlAnalytics);
  }
  // http://52.71.217.35:8082/admin-service/api/v1/viewerAnalytics/viewerCountByRange/19516?dataPatternType=day&fromDate=2025-01-01&toDate=2025-01-10
  getItemAnalyticsByDateRange(id:any,fd:any,td:any,dayPattern:any){
    const urlAnalytics = `${appConfig.getViewerCountByDateRange}/${id}?dataPatternType=${dayPattern}&fromDate=${fd}&toDate=${td}`
    console.log("getItemAnalyticsByDateRange", urlAnalytics)
    return this.resourceService.getData(urlAnalytics);
  }


  getMediaById(id) {
    const getMediaItemUrl = `${appConfig.mediaItem}/${id}`;
    return this.resourceService.getData(getMediaItemUrl);
  }

  checkStatus(streamId) {
    const checkStatusUrl = `${appConfig.schedulingChannelInfo}/streamStatus?appName=ChannelApp&streamId=${streamId}`;
    return this.resourceService.getData(checkStatusUrl);
  }

  // selectAll Album List
  getSelectAllAlbumList(page, size, search, isSelected) {
    const selected = isSelected ? `?isSelected=${isSelected}` : "";
    const pages = isSelected ? `&page=${page}` : `?page=${page}`;
    const filter = search ? `&searchkey=${search}` : "";
    const getAlbumListtUrl = `${appConfig.album}/createAlbum${selected}${pages}${filter}&size=${size}`;
    return this.resourceService.getData(getAlbumListtUrl);
  }

  getAllMusicFolderList(page, size, search, isSelected, smartList) {
    const list = smartList ? `&smartList=${smartList}` : "";
    const selected = isSelected ? `?isSelected=${isSelected}` : "";
    const pages = isSelected ? `&page=${page}` : `?page=${page}`;
    const filter = search ? `&searchkey=${search}` : "";
    const getAlbumListtUrl = `${appConfig.catalog}${selected}${pages}${filter}&size=${size}${list}`;
    return this.resourceService.getData(getAlbumListtUrl);
  }

  getMediaItems(page, size, search, itemType, isSelected) {
    let encodedSearch = encodeURIComponent(search);
    const selected = isSelected ? `?isSelected=${isSelected}` : "";
    const item = isSelected ? `&itemType=${itemType}` : `?itemType=${itemType}`;
    const filter = search ? `&searchkey=${encodedSearch}` : "";
    const getMediaItemUrl = `${appConfig.mediaItem}/getAllMediaItemList${selected}${item}&page=${page}${filter}&size=${size}`;
    return this.resourceService.getData(getMediaItemUrl);
  }

  getMediaItemsOnTop(page, size, selectedId, search, itemType, isSelected) {
    let encodedSearch = encodeURIComponent(search);
    const selected = isSelected ? `?isSelected=${isSelected}` : "";
    const item = isSelected ? `&itemType=${itemType}` : `?itemType=${itemType}`;
    const filter = search ? `&searchkey=${encodedSearch}` : "";
    const selectedOnTop = selectedId ? `&mediaItemId=${selectedId}` : "";
    const getMediaItemUrl = `${appConfig.mediaItem}/getAllMediaItemList${selected}${item}${selectedOnTop}&page=${page}${filter}&size=${size}`;
    return this.resourceService.getData(getMediaItemUrl);
  }

  getSongList(page, size, search) {
    const filter = search ? `&searchkey=${search}` : "";
    const getSongListUrl = `${appConfig.musicList}/getAllMusicList?page=${page}${filter}&size=${size}`;
    return this.resourceService.getData(getSongListUrl);
  }

  // setPaidMediaItemData
  getSetPaidMediaItemData(id, mediaAccessType) {
    const getTabDataByIDUrl = `${appConfig.mediaItem}/setPaidMediaItemData?mediaAccessType=${mediaAccessType}&mediaItemId=${id}`;
    return this.resourceService.getData(getTabDataByIDUrl);
  }

  getMediaItemswithvideo(page, size, search) {
    const filter = search ? `&searchkey=${search}` : "";
    const getMediaItemUrl = `${appConfig.mediaItem}/getAllMediaItemListWithVideo?page=${page}${filter}&size=${size}`;
    return this.resourceService.getData(getMediaItemUrl);
  }

  deleteMediaItem(id) {
    const deleteMediaUrl = `${appConfig.mediaItem}/delete?ids=${id}`;
    return this.resourceService.deleteData(deleteMediaUrl);
  }

  deleteAudioItem(musicId, id, isAudioUrl) {
    if (isAudioUrl) {
      var deleteAudioUrl = `${appConfig.musicList}/${musicId}?isAudioUrl=${isAudioUrl}`;
    } else {
      var deleteAudioUrl = `${appConfig.musicList}/${musicId}?audioId=${id}&isAudioUrl=${isAudioUrl}`;
    }
    return this.resourceService.deleteData(deleteAudioUrl);
  }

  uploadFile(formData, type) {
    const uploadFileUrl = `${appConfig.upload}/${type}`;
    return this.resourceService.postData(uploadFileUrl, formData);
  }

  uploadDocumentFile(formData, type) {
    const uploadFileUrl = `${appConfig.upload}/${type}`;
    return this.resourceService.postNewData(uploadFileUrl, formData);
  }

  uploadVedioFile(formData, type) {
    const uploadFileUrl = `${appConfig.upload}/${type}`;
    return this.resourceService.postNewData(uploadFileUrl, formData);
  }

  duplicateMediaItem(id) {
    const duplicateItemUrl = `${appConfig.mediaItem}/duplicateMediaItem/${id}`;
    return this.resourceService.postData(duplicateItemUrl, {});
  }

  duplicateMusicItem(id) {
    const duplicateItemUrl = `${appConfig.musicList}/duplicate/${id}`;
    return this.resourceService.postData(duplicateItemUrl, {});
  }

  updateMediaItem(id, data, type) {
    const updateMediaUrl = `${appConfig.mediaItem}/${id}`;
    return this.resourceService.putData(updateMediaUrl, data);
  }

  updatePodcastItem(id, data, type) {
    const updatePublishUrl =
      type === "PUBLISH"
        ? `${appConfig.podcast}/publishPodcast/${id}`
        : `${appConfig.podcast}/${id}`;
    return this.resourceService.putData(updatePublishUrl, data);
  }

  updatePodcastItemPub(id, data, type) {
    const updateunPublishUrl =
      type !== "PUBLISH"
        ? `${appConfig.podcast}/unPublishPodcast/${id}`
        : `${appConfig.podcast}/${id}`;
    return this.resourceService.putData(updateunPublishUrl, data);
  }

  updateAlbumItemPub(id, data, type) {
    const updatePublishUrl =
      type === "PUBLISH"
        ? `${appConfig.album}/publishAlbum/${id}`
        : `${appConfig.album}/${id}`;
    return this.resourceService.putData(updatePublishUrl, data);
  }

  updateAlbumItemUnPub(id, data, type) {
    const updateunPublishUrl =
      type !== "PUBLISH"
        ? `${appConfig.album}/unPublishAlbum/${id}`
        : `${appConfig.album}/${id}`;
    return this.resourceService.putData(updateunPublishUrl, data);
  }

  updateUnPubMusicItem(id, data, type) {
    const updateunPublishUrl =
      type !== "PUBLISH"
        ? `${appConfig.musicList}/unPublishMusic/${id}`
        : `${appConfig.musicList}/${id}`;
    return this.resourceService.putData(updateunPublishUrl, data);
  }

  updateMusicItemPub(id, data, type) {
    const updateunPublishUrl =
      type === "PUBLISH"
        ? `${appConfig.musicList}/publishMusic/${id}`
        : `${appConfig.musicList}/${id}`;
    return this.resourceService.putData(updateunPublishUrl, data);
  }
  uploadBase64(stockData) {
    const uploadBaseUrl = `${appConfig.upload}/base64images`;
    return this.resourceService.postData(uploadBaseUrl, stockData);
  }

  // deleteMediaDoc(mediaId, docId, type) {
  //   const itemType = (type === 'video' ? 'videoId' : (type === 'audio' ? 'audioId' : 'documentId'));
  //   const deleteDocUrl = `${appConfig.mediaItem}/${type}/${mediaId}?${itemType}=${docId}`;
  //   return this.resourceService.deleteData(deleteDocUrl);
  // }

  deleteMediaDoc(mediaId, docId, type, isAudioUrl, isTvLogo) {
    const itemType =
      type === "video"
        ? "videoId"
        : type === "audio"
        ? "audioId"
        : "documentId";
    if (docId && (itemType === "audioId" || itemType === "videoId")) {
      var deleteMediaVideoUrl = `${appConfig.mediaItem}/${type}/${mediaId}?${itemType}=${docId}&isAudioUrl=${isAudioUrl}`;
    } else if (docId && itemType === "documentId" && isTvLogo) {
      var deleteMediaVideoUrl = `${appConfig.mediaItem}/${type}/?${itemType}=${docId}&mediaItemId=${mediaId}&isTvLogo=${isTvLogo}`;
    } else if (docId && itemType === "documentId") {
      var deleteMediaVideoUrl = `${appConfig.mediaItem}/${type}/?${itemType}=${docId}&mediaItemId=${mediaId}`;
    } else {
      var deleteMediaVideoUrl = `${appConfig.mediaItem}/audio/${mediaId}?isAudioUrl=${isAudioUrl}`;
    }
    return this.resourceService.deleteData(deleteMediaVideoUrl);
  }

  deleteDeveloperDocument(mediaId, docId, type) {
    const itemType = "documentId";
    if (docId) {
      var deleteMediaVideoUrl = `${appConfig.mediaItem}/${type}/?developerAccountId=${mediaId}&${itemType}=${docId}`;
    }
    return this.resourceService.deleteData(deleteMediaVideoUrl);
  }

  deleteMediaVideo(mediaId, videoId, isVideoUrl) {
    if (videoId) {
      var deleteMediaVideoUrl = `${appConfig.mediaItem}/video/${mediaId}?isVideoUrl=${isVideoUrl}&videoId=${videoId}`;
    } else {
      var deleteMediaVideoUrl = `${appConfig.mediaItem}/video/${mediaId}?isVideoUrl=${isVideoUrl}`;
    }
    return this.resourceService.deleteData(deleteMediaVideoUrl);
  }

  deleteMediaItems(filter, type) {
    console.log(type);

    const deleteMediaItemUrl =
      type === "series"
        ? `${appConfig.mediaSeries}?${filter}`
        : `${appConfig.mediaItem}/delete?${filter}`;
    return this.resourceService.deleteData(deleteMediaItemUrl);
  }

  deleteMusicItems(filter, type) {
    console.log(type);

    const deleteMediaItemUrl =
      type === "series"
        ? `${appConfig.album}?${filter}`
        : `${appConfig.musicList}?${filter}`;
    return this.resourceService.deleteData(deleteMediaItemUrl);
  }

  createMediaSeries(data) {
    const createMediaSeriesUrl = `${appConfig.mediaSeries}/mediaseries`;
    return this.resourceService.postData(createMediaSeriesUrl, data);
  }

  mediaitemAddToFolder(catalogId, mediaItemId) {
    const addToFolderMediaItemUrl = `${appConfig.mediaItem}/${catalogId}/{mediaItemIds}?${mediaItemId}`;
    return this.resourceService.putData(addToFolderMediaItemUrl);
  }

  mediaSeriesAddToFolder(catalogId, mediaseriesId) {
    const addToFolderMediaSeriesUrl = `${appConfig.mediaSeries}/${catalogId}/{mediaseriesId}?${mediaseriesId}`;
    return this.resourceService.putData(addToFolderMediaSeriesUrl);
  }

  albumAddToFolder(catalogId, albumId) {
    const addToFolderAlbumUrl = `${appConfig.album}/${catalogId}/albumId?${albumId}`;
    return this.resourceService.putData(addToFolderAlbumUrl);
  }

  songAddToFolder(catalogId, songId) {
    const addToFolderSongUrl = `${appConfig.musicList}/${catalogId}/musicId?${songId}`;
    return this.resourceService.putData(addToFolderSongUrl);
  }

  getMediaSeries(page, size, search, seriesType) {
    const filter = search ? `&searchkey=${search}` : "";
    const getMediaSeriesUrl = `${appConfig.mediaSeries}/getAllMediaSeriesList?page=${page}${filter}&seriesType=${seriesType}&size=${size}`;
    return this.resourceService.getData(getMediaSeriesUrl);
  }

  searchM3uData(id, searchKey, categoryName, channelType) {
    let encodedSearch = encodeURIComponent(searchKey);
    let encodedField = encodeURIComponent(categoryName);
    if (categoryName && searchKey) {
      var getM3udataUrl = `${appConfig.mediaItem}/${id}/playlist?categoryName=${encodedField}&channelType=${channelType}&searchKey=${encodedSearch}`;
    } else if (categoryName) {
      var getM3udataUrl = `${appConfig.mediaItem}/${id}/playlist?categoryName=${encodedField}&channelType=${channelType}`;
    } else {
      var getM3udataUrl = `${appConfig.mediaItem}/${id}/playlist?channelType=${channelType}&searchKey=${encodedSearch}`;
    }
    return this.resourceService.getData(getM3udataUrl);
  }

  refreshM3uData(id) {
    const getrefreshM3udataUrl = `${appConfig.mediaItem}/${id}/sync`;
    return this.resourceService.putData(getrefreshM3udataUrl);
  }
  // for select All
  getAllMediaSeries(page, size, search, seriesType, isSelected) {
    const selected = isSelected ? `?isSelected=${isSelected}` : "";
    const pages = isSelected ? `&page=${page}` : `?page=${page}`;
    const filter = search ? `&searchkey=${search}` : "";
    const getMediaSeriesUrl = `${appConfig.mediaSeries}/getAllMediaSeriesList${selected}${pages}${filter}&seriesType=${seriesType}&size=${size}`;
    return this.resourceService.getData(getMediaSeriesUrl);
  }
  // selected All songs list
  getSelectedAllSongList(page, size, search, isSelected) {
    const selected = isSelected ? `?isSelected=${isSelected}` : "";
    const pages = isSelected ? `&page=${page}` : `?page=${page}`;
    const filter = search ? `&searchkey=${search}` : "";
    const getSongListUrl = `${appConfig.musicList}/getAllMusicList${selected}${pages}${filter}&size=${size}`;
    return this.resourceService.getData(getSongListUrl);
  }
  // for selectAll In videoseries
  getSelectAllMediaSeries(page, size, search, seriesType, isSelected) {
    const selected = isSelected ? `?isSelected=${isSelected}` : "";
    const pages = isSelected ? `&page=${page}` : `?page=${page}`;
    const filter = search ? `&searchkey=${search}` : "";
    const getMediaSeriesUrl = `${appConfig.mediaSeries}/getAllMediaSeriesList${selected}${pages}${filter}&seriesType=${seriesType}&size=${size}`;
    return this.resourceService.getData(getMediaSeriesUrl);
  }

  getMediaSeriesInDropdown(page, size, search, seriesType) {
    const filter = search ? `&searchkey=${search}` : "";
    const getMediaSeriesUrl = `${appConfig.mediaSeries}/getAllMediaSeriesList?isDropDown=true&page=${page}${filter}&seriesType=${seriesType}&size=${size}`;
    return this.resourceService.getData(getMediaSeriesUrl);
  }

  getMediaSeriesWithMediaItem(page, size, id, search) {
    const filter = id ? `mediaItemId=${id}&` : "";
    const list = search ? `&searchkey=${search}` : "";
    const getMediaSeriesUrl = `${appConfig.mediaSeries}/getAllMediaSeriesList?${filter}page=${page}&${list}&size=${size}`;
    return this.resourceService.getData(getMediaSeriesUrl);
  }

  getMediaSeriesSearch(page, size, search) {
    if (search !== "" && search !== undefined) {
      let getMediaSeriesUrl = `${appConfig.mediaSeries}/getAllMediaSeriesList?page=${page}&size=${size}&searchkey=${search}`;
      return this.resourceService.getData(getMediaSeriesUrl);
    } else {
      let getMediaSeriesUrl = `${appConfig.mediaSeries}/getAllMediaSeriesList?page=${page}&size=${size}`;
      return this.resourceService.getData(getMediaSeriesUrl);
    }
  }

  getMediaSeriesById(id) {
    const getMediaSeriesUrl = `${appConfig.mediaSeries}/${id}`;
    return this.resourceService.getData(getMediaSeriesUrl);
  }
  getNetworkData(date: any, id: number, noOfItems: number) {
    const getNetworkInfoUrl = `${appConfig.mediaSeries}/network/${id}?date=${date}&noOfItems=${noOfItems}`;
    return this.resourceService.getData(getNetworkInfoUrl);
  }
  getM3uDataByCatagory(
    id,
    catagoryName,
    channelType,
    page,
    size
  ): Observable<any> {
    const encodedField = encodeURIComponent(catagoryName);
    const getM3uDataByCatagoryUrl = `${appConfig.mediaItem}/${id}/m3uPlaylist?categoryName=${encodedField}&channelType=${channelType}&page=${page}&size=${size}`;
    return this.resourceService.getData(getM3uDataByCatagoryUrl);
  }

  updateMediaSeries(id, data, type) {
    const updateMediaSeriesUrl = `${appConfig.mediaSeries}/${id}`;
    return this.resourceService.putData(updateMediaSeriesUrl, data);
  }

  removeItem(id, data) {
    const updateMediaSeriesUrl = `${appConfig.mediaSeries}/removeItem/${id}`;
    return this.resourceService.putData(updateMediaSeriesUrl, data);
  }

  deleteMediaSeries(filter) {
    const deleteMediaSeriesUrl = `${appConfig.mediaSeries}?${filter}`;
    return this.resourceService.deleteData(deleteMediaSeriesUrl);
  }

  getMediaItemSeries(page, size, search) {
    const filter = search ? `&searchkey=${search}` : "";
    // const getMediaItemSeriesUrl = `${appConfig.mediaItem}?page=${page}&size=${size}&searchkey=${search}`;
    const getMediaItemSeriesUrl = `${appConfig.mediaItem}?page=${page}&size=${size}${filter}`;
    return this.resourceService.getData(getMediaItemSeriesUrl);
  }

  getListItems(page, size, search) {
    const getListItemUrl = `${appConfig.catalog}?page=${page}&size=${size}&searchkey=${search}`;
    return this.resourceService.getData(getListItemUrl);
  }

  // change media series
  changeSeries(filter, data) {
    console.log(filter);

    const changeSeriesUrl = `${appConfig.mediaSeries}/ids/changeMediaSeries?${filter}`;
    return this.resourceService.putData(changeSeriesUrl, data);
  }

  // move media item
  moveMediaItem(MediaItemId, data) {
    const moveMediaUrl = `${appConfig.mediaItem}/move?mediaItemIds=${MediaItemId}&mediaSeriesId=${data.mediaSeriesId}`;
    return this.resourceService.putData(moveMediaUrl, data);
  }

  // change media series
  changeAlbum(filter, data) {
    console.log(data);

    const changeAlbumUrl = `${appConfig.album}/ids/changeAlbum?albumId=${data.albumId}${filter}`;
    return this.resourceService.putData(changeAlbumUrl);
  }

  // move media item
  moveAlbum(songItemId, data) {
    const moveMediaUrl = `${appConfig.musicList}/move?musicIds=${songItemId}&albumId=${data.albumId}`;
    return this.resourceService.putData(moveMediaUrl, data);
  }

  // get music list
  getAlbumList(page, size, search) {
    const filter = search ? `&searchkey=${search}` : "";
    const getAlbumListtUrl = `${appConfig.album}/createAlbum?page=${page}&${filter}&size=${size}`;

    return this.resourceService.getData(getAlbumListtUrl);
  }

  getAlbumInDropdown(page, size, search) {
    const filter = search ? `&searchkey=${search}` : "";
    const getAlbumListtUrl = `${appConfig.album}/createAlbum?isDropDown=true&page=${page}${filter}&size=${size}`;
    return this.resourceService.getData(getAlbumListtUrl);
  }

  // create album
  createAlbum(data) {
    const createAlbum = `${appConfig.album}/createAlbum`;
    return this.resourceService.postData(createAlbum, data);
  }

  updateAlbum(id, data, type) {
    const updateAlbumUrl = `${appConfig.album}/${id}`;
    return this.resourceService.putData(updateAlbumUrl, data);
  }

  getAllAlbumMusic(albumId) {
    const getAllAlbumMusicUrl = `${appConfig.album}/listOfMusicInAlbum/${albumId}`;
    return this.resourceService.getData(getAllAlbumMusicUrl);
  }

  // get album by id
  getAlbumItemById(id) {
    const getAlbumItemByIdUrl = `${appConfig.album}/${id}`;
    return this.resourceService.getData(getAlbumItemByIdUrl);
  }

  // delete album
  deleteAlbumItem(filter) {
    const deleteAlbumUrl = `${appConfig.album}?${filter}`;
    return this.resourceService.deleteData(deleteAlbumUrl);
  }

  // delete song
  deleteSongItem(filter) {
    const deleteSongUrl = `${appConfig.musicList}?${filter}`;
    return this.resourceService.deleteData(deleteSongUrl);
  }

  // create music
  createMusic(data) {
    const createMusicURL = `${appConfig.musicList}/createMusic`;
    return this.resourceService.postData(createMusicURL, data);
  }

  updateMusic(id, data, type) {
    const updateMusicUrl = `${appConfig.musicList}/${id}`;
    return this.resourceService.putData(updateMusicUrl, data);
  }

  // get Music by id
  getMusicItemById(id) {
    const getMusicItemByIdUrl = `${appConfig.musicList}/${id}`;
    return this.resourceService.getData(getMusicItemByIdUrl);
  }

  // delete Music
  deleteMusicItem(id) {
    if (typeof id === "string") {
      var deleteMusicUrl = `${appConfig.musicList}?${id}`;
    } else {
      var deleteMusicUrl = `${appConfig.musicList}?ids=${id}`;
    }
    return this.resourceService.deleteData(deleteMusicUrl);
  }

  // All Songs

  getAllSongList(page, size, search) {
    var filter = search ? `&searchkey=${search}` : "";
    const getAllSongListURL = `${appConfig.musicList}/getAllMusicList?page=${page}${filter}&size=${size}`;
    return this.resourceService.getData(getAllSongListURL);
  }

  // media Play History

  postMediaPlayCount(data) {
    const postMediaPLayCountURL = `${appConfig.mediaPlay}`;
    return this.resourceService.postData(postMediaPLayCountURL, data);
  }
  // get AppDownloads by Analytics - Day
  getAppDownloadsGraphForDay(startDate, endDate, page, size) {
    const mediaPlaysDay = `${appConfig.appInfo}/getAppDownloadsWithFilterForDay?endDate=${endDate}&isGraph=true&page=${page}&size=${size}&startDate=${startDate}`;
    return this.resourceService.getData(mediaPlaysDay);
  }

  // Get AppDownloads data for table - Day

  getAppDownloadsGraphForDayWithPagination(
    startDate,
    endDate,
    page,
    size,
    isGraph
  ) {
    const mediaPlaysDay = `${appConfig.appInfo}/getAppDownloadsWithFilterForDay?endDate=${endDate}&isGraph=${isGraph}&page=${page}&size=${size}&startDate=${startDate}`;
    return this.resourceService.getData(mediaPlaysDay);
  }

  // Get AppDownloads data for graph - Month

  getAppDownloadsGraphForMonth(startDate, endDate, page, size) {
    const mediaPlaysDay = `${appConfig.appInfo}/getAppDownloadsWithFilterForMonth?endDate=${endDate}&isGraph=true&page=${page}&size=${size}&startDate=${startDate}`;
    return this.resourceService.getData(mediaPlaysDay);
  }

  // Get AppDownloads data for table - Month

  getAppDownloadsGraphForMonthWithPagination(
    startDate,
    endDate,
    page,
    size,
    isGraph
  ) {
    const mediaPlaysDay = `${appConfig.appInfo}/getAppDownloadsWithFilterForMonth?endDate=${endDate}&isGraph=${isGraph}&page=${page}&size=${size}&startDate=${startDate}`;
    return this.resourceService.getData(mediaPlaysDay);
  }

  getAppDownloadsGraphForYear(startDate, endDate, page, size) {
    const mediaPlaysDay = `${appConfig.appInfo}/getAppDownloadsWithFilterForYear?endDate=${endDate}&isGraph=true&page=${page}&size=${size}&startDate=${startDate}`;
    return this.resourceService.getData(mediaPlaysDay);
  }

  getAppDownloadsGraphForYearWithPagination(
    startDate,
    endDate,
    page,
    size,
    isGraph
  ) {
    const mediaPlaysDay = `${appConfig.appInfo}/getAppDownloadsWithFilterForYear?endDate=${endDate}&isGraph=${isGraph}&page=${page}&size=${size}&startDate=${startDate}`;
    return this.resourceService.getData(mediaPlaysDay);
  }

  getAppLaunchesGraphForDay(startDate, endDate, page, size) {
    const mediaPlaysDay = `${appConfig.appInfo}/getAppLaunchesWithFilterForDay?endDate=${endDate}&isGraph=true&page=${page}&size=${size}&startDate=${startDate}`;
    return this.resourceService.getData(mediaPlaysDay);
  }

  getAppLaunchesGraphForDayWithPagination(
    startDate,
    endDate,
    page,
    size,
    isGraph
  ) {
    const mediaPlaysDay = `${appConfig.appInfo}/getAppLaunchesWithFilterForDay?endDate=${endDate}&isGraph=${isGraph}&page=${page}&size=${size}&startDate=${startDate}`;
    return this.resourceService.getData(mediaPlaysDay);
  }

  ///
  getAppLaunchesGraphForMonth(startDate, endDate, page, size) {
    const mediaPlaysDay = `${appConfig.appInfo}/getAppLaunchesWithFilterForMonth?endDate=${endDate}&isGraph=true&page=${page}&size=${size}&startDate=${startDate}`;
    return this.resourceService.getData(mediaPlaysDay);
  }

  getAppLaunchesGraphForMonthWithPagination(
    startDate,
    endDate,
    page,
    size,
    isGraph
  ) {
    const mediaPlaysDay = `${appConfig.appInfo}/getAppLaunchesWithFilterForMonth?endDate=${endDate}&isGraph=${isGraph}&page=${page}&size=${size}&startDate=${startDate}`;
    return this.resourceService.getData(mediaPlaysDay);
  }

  getAppLaunchesGraphForYear(startDate, endDate, page, size) {
    const mediaPlaysDay = `${appConfig.appInfo}/getAppLaunchesWithFilterForYear?endDate=${endDate}&isGraph=true&page=${page}&size=${size}&startDate=${startDate}`;
    return this.resourceService.getData(mediaPlaysDay);
  }

  getAppLaunchesGraphForYearWithPagination(
    startDate,
    endDate,
    page,
    size,
    isGraph
  ) {
    const mediaPlaysDay = `${appConfig.appInfo}/getAppLaunchesWithFilterForYear?endDate=${endDate}&isGraph=${isGraph}&page=${page}&size=${size}&startDate=${startDate}`;
    return this.resourceService.getData(mediaPlaysDay);
  }

  getAppDownloadsCumulativeGraph(data, startDate, endDate) {
    const mediaCumulativeData = `${appConfig.appInfo}/appDownloadCumulativeGraph?dataPatternType=${data}&endDate=${endDate}&startDate=${startDate}`;
    return this.resourceService.getData(mediaCumulativeData);
  }

  getAppLaunchesCumulativeGraph(data, startDate, endDate) {
    const mediaCumulativeData = `${appConfig.appInfo}/appLaunchesCumulativeGraph?dataPatternType=${data}&endDate=${endDate}&startDate=${startDate}`;
    return this.resourceService.getData(mediaCumulativeData);
  }

  // get analytics days

  getMediaPlaysGraphForDay(startDate, endDate, page, size, isGraph) {
    const mediaPlaysDay = `${appConfig.analytics}/mediaPlaysGraphForDay?endDate=${endDate}&isGraph=${isGraph}&page=${page}&size=${size}&startDate=${startDate}`;
    return this.resourceService.getData(mediaPlaysDay);
  }

  getMediaPlaysGraphForMonth(startDate, endDate, page, size) {
    const mediaPlaysMonth = `${appConfig.analytics}/mediaPlaysGraphForMonthly?endDate=${endDate}&isGraph=true&page=${page}&size=${size}&startDate=${startDate}`;
    return this.resourceService.getData(mediaPlaysMonth);
  }

  getMediaPlaysGraphForYear(startYear, endYear, page, size) {
    const mediaPlaysYear = `${appConfig.analytics}/mediaPlaysGraphForYearly?endDate=${endYear}&isGraph=true&page=${page}&size=${size}&startDate=${startYear}`;
    return this.resourceService.getData(mediaPlaysYear);
  }

  getMediaPlaysGraphForMonthWithPagination(
    startDate,
    endDate,
    page,
    size,
    isGraph
  ) {
    const mediaPlaysMonth = `${appConfig.analytics}/mediaPlaysGraphForMonthly?endDate=${endDate}&isGraph=${isGraph}&page=${page}&size=${size}&startDate=${startDate}`;
    return this.resourceService.getData(mediaPlaysMonth);
  }

  getMediaPlaysGraphForDayWithPagination(
    startDate,
    endDate,
    page,
    size,
    isGraph
  ) {
    const mediaPlaysDay = `${appConfig.analytics}/mediaPlaysGraphForDay?endDate=${endDate}&isGraph=${isGraph}&page=${page}&size=${size}&startDate=${startDate}`;
    return this.resourceService.getData(mediaPlaysDay);
  }

  getMediaPlaysCumulativeGraph(data, startDate, endDate) {
    const mediaCumulativeData = `${appConfig.analytics}/mediaPlaysCumulativeGraph?dataPatternType=${data}&endDate=${endDate}&startDate=${startDate}`;
    console.log(mediaCumulativeData , "mediaCumulativeData")
    return this.resourceService.getData(mediaCumulativeData);
  }

  updateOrderOfMediaItem(data) {
    const updateSortorderUrl = `${appConfig.mediaItem}/updateSortOrderOfMediaItem`;
    return this.resourceService.putData(updateSortorderUrl, data);
  }

  getOriginalVideoUrl(id) {
    const getOriginalVideoUrl = `${appConfig.mediaItem}/videoId/${id}`;
    return this.resourceService.getData(getOriginalVideoUrl);
    // return this.http.get(getOriginalVideoUrl, { responseType: "blob" });
  }

  downloadVideoFromBlob(id) {
    const downloadVideoFromBlobUrl = `${appConfig.upload}/loadVideo/${id}`;
    // return this.resourceService.getData(downloadVideoFromBlobUrl)
    return this.http.get(downloadVideoFromBlobUrl, { responseType: "blob" });
  }
  getLiveStreamSettingById(id) {
    const url = `${appConfig.liveStreamingSetting}/${id}`;
    return this.resourceService.getData(url);
  }

  updateLiveStreamSettingBYId(id, data) {
    const url = `${appConfig.liveStreamingSetting}/${id}`;
    return this.resourceService.putData(url, data);
  }

  //facebook stream url

  getFacebookStreamUrl(data, itemId) {
    const url = `${appConfig.liveStreaming}/getFaceBookStreamUrl?mediaItemId=${itemId}`;
    return this.resourceService.putData(url, data);
  }

  getYouTubeUrl(id, itemId) {
    const filter = id ? `id=${id}&` : "";
    const url = `${appConfig.liveStreaming}/getYouTubeStreamUrl?${filter}mediaItemId=${itemId}`;
    return this.resourceService.putData(url);
  }

  getYouTubeStatusUrl(itemId) {
    const url = `${appConfig.liveStreaming}/getYouTubeStatus?socialMediaId=${itemId}`;
    return this.resourceService.getData(url);
  }

  updateYoutubeRTMPUrl(id, mediaItemId) {
    const url = `${appConfig.liveStreaming}/updateYouTubeRTMPURL?id=${id}&mediaItemId=${mediaItemId}`;
    return this.resourceService.putData(url);
  }

  startStreaming(id) {
    const url = `${appConfig.liveStreaming}/startStreaming/${id}`;
    return this.resourceService.putData(url);
  }

  stopStreaming(id) {
    const url = `${appConfig.liveStreaming}/stopStreaming/${id}`;
    return this.resourceService.putData(url);
  }

  getViewerCount(id) {
    const url = `${appConfig.liveStreaming}/viewerCount/${id}`;
    return this.resourceService.getData(url);
  }

  getBroadcastStatusById(id) {
    const url = `${appConfig.liveStreaming}/broadCastStatus/${id}`;
    return this.resourceService.getData(url);
  }

  connectTwitterorInstagram(data) {
    const url = `${appConfig.socialMediaLiveStreaming}`;
    return this.resourceService.postData(url, data);
  }

  updateConnectTwitterorInstagram(data, id) {
    const url = `${appConfig.socialMediaLiveStreaming}?id=${id}`;
    return this.resourceService.putData(url, data);
  }

  getDeviceType(id) {
    const url = `${appConfig.liveStreaming}/${id}`;
    return this.resourceService.getData(url);
  }

  uploadChunkVideo(data) {
    const url = `${appConfig.upload}/video/chunk`;
    return this.resourceService.postNewData(url, data);
  }

  removeTwitterOrInsta(id) {
    const url = `${appConfig.socialMediaLiveStreaming}?id=${id}`;
    return this.resourceService.deleteData(url);
  }

  getSchedulingChannelInfoWithDate(date: any, mediaItemId: any, isForM3U8 = false, isInitialValue = false) {
    const schedulingChannelInfoUrl = `${appConfig.schedulingChannelInfo}?date=${date}&mediaItemId=${mediaItemId}&isForM3U8=${isForM3U8}&isInitial=${isInitialValue}`;
    return this.resourceService.getData(schedulingChannelInfoUrl);
  }

  updateSchedulingChannelPlaylist(channelId, date, playlistType, data , isForM3U8 = false) {
    const updateschedulingChannelPlaylist = `${appConfig.schedulingChannelInfo}?channelId=${channelId}&date=${date}&playlistType=${playlistType}&isForM3U8=${isForM3U8}`;
    return this.resourceService.putData(updateschedulingChannelPlaylist, data);
  }

  startStopAutomation(channelInfoId, startAutomation) {
    const startStopAutomationURL = `${appConfig.schedulingChannelInfo}/automation?channelInfoId=${channelInfoId}&startAutomation=${startAutomation}`;
    return this.resourceService.putData(startStopAutomationURL);
  }

  getM3uData(mediaItemId, m3uType, page, size) {
    if (page && size) {
      var m3uDataByTypeURL = `${appConfig.mediaItem}/${mediaItemId}/m3uData?channelType=${m3uType}&deviceType=WEB&page=${page}&size=${size}`;
    } else {
      var m3uDataByTypeURL = `${appConfig.mediaItem}/${mediaItemId}/m3uData?channelType=${m3uType}&deviceType=WEB`;
    }
    return this.resourceService.getData(m3uDataByTypeURL);
    // mediaItem/59540/m3uChannel?channelType=M3U_MOVIES&organizationId=557&page=1&size=15
  }

  getM3uDataForTv(
    mediaItemId: any,
    m3uType: any,
    date: any,
    page: any,
    size: any,
    noOfItem: any,
    categoryName: any
  ) {
    if (page && size) {
      if (m3uType === "M3U_CHANNEL") {
        var m3uDataByTypeURL = `${appConfig.mediaItem}/${mediaItemId}/m3uData?channelType=${m3uType}&date=${date}&page=${page}&size=${size}`;
      } else {
        if (categoryName) {
          let encodeCategoryName = encodeURIComponent(categoryName);
          var m3uDataByTypeURL = `${appConfig.mediaItem}/${mediaItemId}/m3uPlaylist?categoryName=${encodeCategoryName}&channelType=${m3uType}&page=${page}&size=${size}`;
        } else {
          var m3uDataByTypeURL = `${appConfig.mediaItem}/${mediaItemId}/m3uData?channelType=${m3uType}&page=${page}&size=${size}`;
        }
      }
    } else {
      var m3uDataByTypeURL = `${appConfig.mediaItem}/${mediaItemId}/m3uData?channelType=${m3uType}&deviceType=WEB&noOfItems=${noOfItem}`;
    }
    return this.resourceService.getData(m3uDataByTypeURL);
    // mediaItem/59540/m3uChannel?channelType=M3U_MOVIES&organizationId=557&page=1&size=15
  }
  getM3uCategory(mediaItemId: any, m3uType: any) {
    const m3uCategoryData = `${appConfig.mediaItem}/${mediaItemId}/category?channelType=${m3uType}`;
    return this.resourceService.getData(m3uCategoryData);
  }

  setStartDate(date: string) {
    this.startDate = date;
  }

  setEndDate(date: string) {
    this.endDate = date;
  }

  getStartDate() {
    return this.startDate;
  }

  getEndDate() {
    return this.endDate;
  }
  getViewsCount(mediaItemId:any){
    const getViewsCountURL = `${appConfig.organization}/viewersCount/${mediaItemId}`
    return this.resourceService.getData(getViewsCountURL)
  }
  getLiveViewers(date:any, mediaItemId:any, type: any){
    const getViewersURL = `${appConfig.organization}/liveStreamViewerDetails?date=${date}&mediaItemId=${mediaItemId}&type=${type}`
    return this.resourceService.getData(getViewersURL)
  }

  getM3u8SchedulingChannel(date: any, mediaItemId: any,page, size) {
    const schedulingChannelInfoUrl = `${appConfig.schedulingChannelInfo}/${mediaItemId}?page=${page}&size=${size}`;
    return this.resourceService.getData(schedulingChannelInfoUrl);
  }

  createM3u8Item(data:any, mediaItemId: any) {
    const url = `${appConfig.schedulingChannelInfo}/${mediaItemId}`;
    return this.resourceService.postData(url, data);
  }

  getM3u8MediaItemById(id) {
    const getMediaUrl = `${appConfig.schedulingChannelInfo}/schedulingInfo/${id}`;
    return this.resourceService.getData(getMediaUrl);
  }
  updateM3u8Item(data,id) {
    const updateM3u8ItemUrl = `${appConfig.schedulingChannelInfo}/schedulingInfo/${id}`;
    return this.resourceService.putData(updateM3u8ItemUrl, data);
  }
  deleteM3u8Item(id) {
    const updateMediaUrl = `${appConfig.schedulingChannelInfo}/schedulingInfo/${id}`;
    return this.resourceService.deleteData(updateMediaUrl);
  }
}
