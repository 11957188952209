import { SharedService } from "./../../../services/shared/shared.service";
import { Subscription } from "rxjs";
import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from "@angular/core";
import { Router } from "@angular/router";
import { SecurityService } from "src/app/services/security/security.service";
// import { FeatureControlService } from 'src/app/services/feature-control.service';
import { CustomerService } from "src/app/services/customer/customer.service";
@Component({
  selector: "app-sidebar",
  templateUrl: "./sidebar.component.html",
  styleUrls: ["./sidebar.component.css"],
})
export class SidebarComponent implements OnInit, OnDestroy {
  userRoles: any[] = [];
  @Input() panelState;
  @Output() closeSidebarPanel = new EventEmitter();
  subscriptonData = new Subscription();
  subscriptonProgressData = new Subscription();
  currentUrl: any;
  subscriptionPlan: any[] = [];
  premium = false;
  standard = false;
  basic = false;
  hide = false;
  isPaymentDone = true;
  activeCard = true;
  stopInterval: any;
  progress: number = 0;
  videoName: any;
  progressInfos: any;
  subscriptionPlanName: any;
  profitable: any;
  showUpdatePopup: boolean;
  progressData: any[] = [];
  hideGiving = false;
  transcode = true;
  dashboard = true;
  customers = true;
  emailTemp = true;
  subscription = true;
  expense = true;
  revenue = true;
  totalSpent = true;
  sumarry = true;
  securityGroup = true;
  uploadStock = true;
  request = true;
  settings = true;
  accounting = true;
  onBoardinguser = false;
  showMenu = false;
  permissionArray: any[] = [];
  featureList: any[] = [];
  showContact: boolean;
  showEmail: boolean;
  showSocialMedia: boolean;
  showEvent: boolean;
  showLibrary: boolean;
  showRss: boolean;
  showLinks: boolean;
  showFolder: boolean;
  showWeb: boolean;
  showMobileApp: boolean;
  showLiveStream: boolean;
  showBookStore: boolean;
  showContactUs: boolean;
  showTvApp: boolean;
  showAnalytics: boolean;
  showNotification: boolean;
  showPodcast: boolean;
  showMusic: boolean;
  giving: boolean;
  showEmbed: boolean;
  showVOD: boolean;
  showApps = true;
  showMedia = true;
  showGiving: boolean;
  showLive: boolean;
  switchView = false;
  showChannel: boolean;
  showHideChannel: boolean;
  showFiller = false;
  value: any = "side";
  isCollapsedMedia = true;
  isCollapsedApps = true;
  isCollapsedDataUsage = true;
  isCollapsedWebsite = true;
  isCollapsedEvents = true;
  isCollapsedGiving = true;
  isCollapsedLibrary = true;
  isCollapsedSubscription = true;
  isCollapsedSocialMedia = true;
  isCollapsedContactUs = true;
  isCollapsedSettings = true;
  showactive = false;
  mobileAppAndroid: boolean;
  mobileAppIos: boolean;
  public features: any[] = [];
  constructor(
    public sharedService: SharedService,
    public router: Router,
    public securityService: SecurityService,
    // private featureControlService: FeatureControlService,
    public customerService: CustomerService
  ) {
    document.addEventListener("click", this.clickHandler.bind(this));

    this.currentUrl = window.location.href;
    var tmpVar = this.currentUrl.includes("/dashboard");
    if (this.currentUrl.includes("/dashboard")) {
      window.onpopstate = function (event) {
        history.go(1);
      };
    }
  }

  ngOnInit(): void {
    if (window.outerWidth < 529) {
      this.value = "over";
    } else {
      this.value = "side";
    }
    // this.featureControlService.featureList();

    this.getSubsciptionData();
    this.getProgressDataData();
  }

  toggle(type) {
    if (type === "media") {
      this.isCollapsedMedia = !this.isCollapsedMedia;
      this.isCollapsedApps = true;
      this.isCollapsedWebsite = true;
      this.isCollapsedEvents = true;
      this.isCollapsedGiving = true;
      this.isCollapsedLibrary = true;
      this.isCollapsedSubscription = true;
      this.isCollapsedSocialMedia = true;
      this.isCollapsedContactUs = true;
      this.isCollapsedSettings = true;
      this.showactive = true;
    } else if (type === "apps") {
      this.isCollapsedMedia = true;
      this.isCollapsedApps = !this.isCollapsedApps;
      this.isCollapsedWebsite = true;
      this.isCollapsedEvents = true;
      this.isCollapsedGiving = true;
      this.isCollapsedLibrary = true;
      this.isCollapsedSubscription = true;
      this.isCollapsedSocialMedia = true;
      this.isCollapsedContactUs = true;
      this.isCollapsedSettings = true;
      this.showactive = false;
    } else if (type === "events") {
      this.isCollapsedMedia = true;
      this.isCollapsedApps = true;
      this.isCollapsedWebsite = true;
      this.isCollapsedGiving = true;
      this.isCollapsedEvents = !this.isCollapsedEvents;
      this.isCollapsedLibrary = true;
      this.isCollapsedSubscription = true;
      this.isCollapsedSocialMedia = true;
      this.isCollapsedContactUs = true;
      this.isCollapsedSettings = true;
      this.showactive = false;
    } else if (type === "giving") {
      this.isCollapsedMedia = true;
      this.isCollapsedApps = true;
      this.isCollapsedWebsite = true;
      this.isCollapsedGiving = !this.isCollapsedGiving;
      this.isCollapsedEvents = true;
      this.isCollapsedLibrary = true;
      this.isCollapsedSubscription = true;
      this.isCollapsedSocialMedia = true;
      this.isCollapsedContactUs = true;
      this.isCollapsedSettings = true;
      this.showactive = false;
    } else if (type === "library") {
      this.isCollapsedMedia = true;
      this.isCollapsedApps = true;
      this.isCollapsedWebsite = true;
      this.isCollapsedEvents = true;
      this.isCollapsedGiving = true;
      this.isCollapsedLibrary = !this.isCollapsedLibrary;
      this.isCollapsedSubscription = true;
      this.isCollapsedSocialMedia = true;
      this.isCollapsedContactUs = true;
      this.isCollapsedSettings = true;
      this.showactive = true;
    } else if (type === "subscription") {
      this.isCollapsedMedia = true;
      this.isCollapsedApps = true;
      this.isCollapsedWebsite = true;
      this.isCollapsedEvents = true;
      this.isCollapsedGiving = true;
      this.isCollapsedLibrary = true;
      this.isCollapsedSubscription = !this.isCollapsedSubscription;
      this.isCollapsedSocialMedia = true;
      this.isCollapsedContactUs = true;
      this.isCollapsedSettings = true;
      this.showactive = false;
    } else if (type === "socialMedia") {
      this.isCollapsedMedia = true;
      this.isCollapsedApps = true;
      this.isCollapsedWebsite = true;
      this.isCollapsedEvents = true;
      this.isCollapsedGiving = true;
      this.isCollapsedLibrary = true;
      this.isCollapsedSubscription = true;
      this.isCollapsedSocialMedia = !this.isCollapsedSocialMedia;
      this.isCollapsedContactUs = true;
      this.isCollapsedSettings = true;
      this.showactive = false;
    } else if (type === "contactUs") {
      this.isCollapsedMedia = true;
      this.isCollapsedApps = true;
      this.isCollapsedWebsite = true;
      this.isCollapsedEvents = true;
      this.isCollapsedGiving = true;
      this.isCollapsedLibrary = true;
      this.isCollapsedSubscription = true;
      this.isCollapsedSocialMedia = true;
      this.isCollapsedContactUs = !this.isCollapsedContactUs;
      this.isCollapsedSettings = true;
      this.showactive = false;
    } else if (type === "settings") {
      this.isCollapsedMedia = true;
      this.isCollapsedApps = true;
      this.isCollapsedWebsite = true;
      this.isCollapsedEvents = true;
      this.isCollapsedGiving = true;
      this.isCollapsedLibrary = true;
      this.isCollapsedSubscription = true;
      this.isCollapsedSocialMedia = true;
      this.isCollapsedContactUs = true;
      this.isCollapsedSettings = !this.isCollapsedSettings;
      this.showactive = false;
    } else if (type === "Support") {
      // this.isCollapsedMedia = true;
      this.isCollapsedDataUsage = true;
      this.isCollapsedMedia = !this.isCollapsedMedia;
    } else if(type === "dataUsage"){
      // this.isCollapsedDataUsage = true;
      this.isCollapsedMedia = true;
      this.isCollapsedDataUsage = !this.isCollapsedDataUsage;
    }
    else {
      this.isCollapsedMedia = true;
      this.isCollapsedApps = true;
      this.isCollapsedWebsite = true;
      this.isCollapsedEvents = true;
      this.isCollapsedGiving = true;
      this.isCollapsedLibrary = true;
      this.isCollapsedSubscription = true;
      this.isCollapsedSocialMedia = true;
      this.isCollapsedContactUs = true;
      this.isCollapsedSettings = true;
      this.isCollapsedDataUsage = true;
      this.showactive = false;
    }

    // this.isCollapsedMedia = !this.isCollapsedMedia
    // this.isCollapsedApps = !this.isCollapsedApps
    // this.isCollapsedWebsite = !this.isCollapsedWebsite
    // this.isCollapsedEvents = !this.isCollapsedEvents
    // this.isCollapsedLibrary = !this.isCollapsedLibrary
    // this.isCollapsedSubscription = !this.isCollapsedSubscription
    // this.isCollapsedSocialMedia = !this.isCollapsedSocialMedia
    // this.isCollapsedContactUs = !this.isCollapsedContactUs
    // this.isCollapsedSettings = !this.isCollapsedSettings
  }

  onResize(event: any) {
    if (event.target.outerWidth < 529) {
      this.value = "over";
    } else {
      this.value = "side";
    }
  }

  updateProgressValue(index, progressInfos) {
    // for (let i = 0; i < progressInfos.length; i++) {
    //   progressInfos = localStorage.getItem('progress') ? JSON.parse(localStorage.getItem('progress')) : ''
    //   if(progressInfos[i].value === 0){
    //       clearInterval(this.stopInterval);
    //       // this.progress = 0
    //       // this.getMediaItemData(this.mediaItemId);
    //     }
    // }
    let newData = localStorage.getItem("progress")
      ? JSON.parse(localStorage.getItem("progress"))
      : "";
    if (newData.length === 0) {
      clearInterval(this.stopInterval);
    }
    this.progressInfos[index].value = newData[index].value;
    if (this.progressInfos[index].value === 0) {
      this.progressInfos.splice(index, 1);
      localStorage.setItem("progress", JSON.stringify(this.progressInfos));
      var checkData = localStorage.getItem("progress")
        ? JSON.parse(localStorage.getItem("progress"))
        : "";
      if (checkData.length === 0) {
        this.showUpdatePopup = false;
      }
    }
    // setInterval(
    // console.log('in invetrval');
    // this.videoName = localStorage.getItem('itemName').toString()
    // if(parseInt(progressCount) === 0){
    //   clearInterval(this.stopInterval);
    //   this.progress = 0
    //   // this.getMediaItemData(this.mediaItemId);
    // }
    // // ,500)
    // console.log(progressCount);
    // if(parseInt(progressCount)){
    //   this.progress = parseInt(progressCount);
    //   // console.log(this.progress);
    // }
  }

  /**
   * To get subscription data
   */
  getSubsciptionData() {
    this.subscriptonData.add(
      this.sharedService.updateUserRole$.subscribe((role: any) => {
        console.log(role , "role")
        this.userRoles = role;
        this.switchView = localStorage.getItem("loggedInUser")
          ? JSON.parse(localStorage.getItem("loggedInUser")).switchedView
          : false;
        if (role[0] === "ROLE_ON_BOARDING") {
          var id = localStorage.getItem("loggedInUser")
            ? JSON.parse(localStorage.getItem("loggedInUser")).userDetails
                .securityGroup.id
            : null;
          this.getGroupData(id);
        }

        if (this.currentUrl.includes("dashboard")) {
          window.onpopstate = function (event) {
            history.go(1);
          };
        }

        if (
          this.router.url.includes("media-module/media") ||
          this.router.url.includes("library-module/library")
        ) {
          this.showactive = true;
        } else {
          this.showactive = false;
        }
        if (this.router.url.includes("media-module/media/live-streaming")) {
          this.showactive = false;
        }
        // this.subscriptionPlanName = localStorage.getItem('loggedInUser') ? JSON.parse(localStorage.getItem('loggedInUser')).userDetails.organization.subscriptionPlanName : '';
        // this.profitable = localStorage.getItem('loggedInUser') ? JSON.parse(localStorage.getItem('loggedInUser')).userDetails.organization.profit : '';
        // if(this.subscriptionPlanName === 'Premium' || this.profitable === true){
        //   this.hideGiving = true;
        // }
       
        // Commented this to resolve Jira 2289 beacuse this is not generic solution beacuse this is working
        //  only on route insted we have make this on is payment key
        // if (
        //   this.router.url === "/payment-module/payment" ||
        //   this.router.url === "AI/chat-support"
        // ) {
        //   this.isPaymentDone = false;
        //   this.activeCard = false;
        // } else {
        //   this.isPaymentDone = true;
        //   this.activeCard = true;
        // }

        console.log(role ,"Role")
      
        if (
          (this.router.url === "/payment-module/payment" ||
            this.router.url === "AI/chat-support" || "/profile/profile/my-profile") && role && role[0] !== "ROLE_SUPER_ADMIN" && role[0] !== "ROLE_ON_BOARDING"  && JSON.parse(localStorage.getItem("loggedInUser")) && !JSON.parse(localStorage.getItem("loggedInUser")).userDetails.isPaymentDone
        ) {
          this.isPaymentDone = false;
          this.activeCard = false;
        } else {
          this.isPaymentDone = true;
          this.activeCard = true;
        }

        this.subscriptionPlan = localStorage.getItem("loggedInUser")
          ? JSON.parse(localStorage.getItem("loggedInUser")).userDetails
              .organization.subscription[0]
          : [];
        if (this.subscriptionPlan) {
          if (this.subscriptionPlan["subscriptionPlanName"] === "Premium") {
            this.premium = true;
            this.standard = false;
            this.basic = false;
          }
          if (this.subscriptionPlan["subscriptionPlanName"] === "Standard") {
            this.premium = false;
            this.standard = true;
            this.basic = false;
          }
          if (this.subscriptionPlan["subscriptionPlanName"] === "Basic") {
            this.premium = false;
            this.standard = false;
            this.basic = true;
          }
        }
        let liveStreamShowHide = localStorage.getItem("loggedInUser")
          ? JSON.parse(localStorage.getItem("loggedInUser")).userDetails
              .organization.liveStreamShowHide
          : "";
        let channelShowHide = localStorage.getItem("loggedInUser")
          ? JSON.parse(localStorage.getItem("loggedInUser")).userDetails
              .organization.channelShowHide
          : "";
        if (liveStreamShowHide === "true") {
          this.showLiveStream = true;
        } else {
          if (this.router.url.includes("live-streaming")) {
            this.router.navigate(["dashboard"]);
          }
          this.showLiveStream = false;
        }
        if (channelShowHide === "true") {
          this.showChannel = true;
        } else {
          if (this.router.url.includes("channel-module")) {
            this.router.navigate(["dashboard"]);
          }
          this.showChannel = false;
        }
        this.featureList = localStorage.getItem("loggedInUser")
          ? JSON.parse(localStorage.getItem("loggedInUser")).userDetails
              .featureList
          : null;
        if (this.featureList) {
          this.showContact = false;
          this.showEmail = false;
          this.showSocialMedia = false;
          this.showEvent = false;
          this.showLibrary = false;
          this.showRss = false;
          this.showLinks = false;
          this.showFolder = false;
          this.showGiving = false;
          this.showWeb = false;
          this.showMobileApp = false;
          this.showLive = false;
          this.showBookStore = false;
          this.showContactUs = false;
          this.showTvApp = false;
          this.showAnalytics = false;
          this.showNotification = false;
          this.showMusic = false;
          this.showPodcast = false;
          this.giving = false;
          this.showEmbed = false;
          this.showVOD = false;
          this.showHideChannel = false;
          this.featureList.forEach((el) => {
            if (el.name === "Contacts") {
              this.showContact = el.isView;
            }
            if (el.name === "Email Templates") {
              this.showEmail = el.isView;
            }
            if (el.name === "Social Media") {
              this.showSocialMedia = el.isView;
            }
            if (el.name === "Events") {
              this.showEvent = el.isView;
            }
            if (el.name === "Library") {
              this.showLibrary = el.isView;
            }
            if (el.name === "RSS Feeds") {
              this.showRss = el.isView;
            }
            if (el.name === "Links") {
              this.showLinks = el.isView;
            }
            if (el.name === "Folder") {
              this.showFolder = el.isView;
            }
            if (el.name === "Website") {
              this.showWeb = el.isView;
            }
            if (el.name === "Mobile App") {
              this.showMobileApp = el.isView;
              const mobileAppSubfeatures = el.subFeature;
              if (mobileAppSubfeatures && mobileAppSubfeatures.length > 0) {
                mobileAppSubfeatures.forEach((subfeature) => {
                  if (subfeature.name === "Android Mobile App") {
                    this.mobileAppAndroid = subfeature.isView;
                    // console.log(
                    //   "check tv app android subfeature---",
                    //   this.mobileAppAndroid
                    // );
                  }
                  if (subfeature.name === "IOS Mobile App") {
                    this.mobileAppIos = subfeature.isView;
                    // console.log(
                    //   "check mobile app ios subfeature---",
                    //   this.mobileAppIos
                    // );
                  }
                });
              }
            }
            if (el.name === "Bookstore") {
              this.showBookStore = el.isView;
            }
            if (el.name === "Contact Us") {
              this.showContactUs = el.isView;
            }
            if (el.name === "TV App") {
              this.showTvApp = el.isView;
            }
            if (el.name === "Analytics") {
              this.showAnalytics = el.isView;
            }
            if (el.name === "Live Streaming") {
              this.showLive = el.isView;
            }
            if (el.name === "Push Notification") {
              this.showNotification = el.isView;
            }
            if (el.name === "Podcast") {
              this.showPodcast = el.isView;
            }
            if (el.name === "Music") {
              this.showMusic = el.isView;
            }
            if (el.name === "Giving") {
              this.showGiving = el.isView;
            }
            this.profitable = localStorage.getItem("loggedInUser")
              ? JSON.parse(localStorage.getItem("loggedInUser")).userDetails
                  .organization.profit
              : "";
            if (this.showGiving && this.profitable === false) {
              this.hideGiving = true;
            } else {
              this.hideGiving = false;
            }
            if (el.name === "Channels") {
              this.showHideChannel = el.isView;
            }
            this.profitable = localStorage.getItem("loggedInUser")
              ? JSON.parse(localStorage.getItem("loggedInUser")).userDetails
                  .organization.profit
              : "";
            if (this.showGiving && this.profitable === false) {
              this.hideGiving = true;
            } else {
              this.hideGiving = false;
            }

            if (el.name === "Embed") {
              this.showEmbed = el.isView;
            }
            if (el.name === "Videos") {
              this.showVOD = el.isView;
            }
            // if(this.showMobileApp === false && this.showTvApp === false && this.showAnalytics === false && this.showNotification === false){
            //   this.showApps = false
            // }
            // if(this.showPodcastandMusic === false &&
            //    this.showEmbed === false &&
            //    this.showVOD === false &&
            //    this.showAnalytics === false && this.showLive === false){
            //      this.showMedia = false
            //    }
          });
          if (
            (this.showMobileApp === false ||
              (this.mobileAppAndroid && this.mobileAppIos)) &&
            this.showTvApp === false &&
            this.showAnalytics === false &&
            this.showNotification === false
          ) {
            this.showApps = false;
          } else {
            this.showApps = true;
          }
          if(!this.showHideChannel && !this.showVOD){
            this.showTvApp = false
          }
          else{
            this.showTvApp = true;
          }
          if (
            this.showPodcast === false &&
            this.showMusic === false &&
            this.showEmbed === false &&
            this.showVOD === false &&
            this.showAnalytics === false
          ) {
            this.showMedia = false;
          } else {
            this.showMedia = true;
          }
          // if (!this.showVOD && !this.showFolder) {
          //   this.showTvApp = false;
          // }
        }

        // if( !this.showVOD && !this.showPodcastandMusic && !this.showBookStore && !this.showAnalytics && !this.showEmbed && !this.showLive){
        //   this.showMedia = false
        // }else{
        //   this.showMedia = true
        // }

        // if (this.showMobileApp) {
        //   if (!this.activeCard) {
        //     this.router.navigate(["/payment-module/payment"]);
        //   } else if (this.mobileAppAndroid || this.mobileAppIos) {
        //     this.router.navigate(["/apps-module/apps/mobileApp"]);
        //   } else {
        //     this.router.navigate(["/apps-module/apps/tvApp"]);
        //   }
        // }
      })
    );

    this.subscriptonData.add(
      this.sharedService.updateGroupData$.subscribe((response: any) => {
        if (this.userRoles[0] === "ROLE_ON_BOARDING") {
          this.getGroupData(response);
        }
      })
    );

    this.subscriptonData.add(
      this.sharedService.updateSideBarControl$.subscribe((updateData: any) => {
        this.hideGiving = updateData;
        console.log(this.hideGiving);
      })
    );
  }

  getGroupData(groupId) {
    this.securityService.getGroupData(groupId).subscribe((response) => {
      this.permissionArray = response.data ? response.data.permission : [];
      this.dashboard = false;
      this.customers = false;
      this.emailTemp = false;
      this.subscription = false;
      this.expense = false;
      this.revenue = false;
      this.totalSpent = false;
      this.sumarry = false;
      this.securityGroup = false;
      this.uploadStock = false;
      this.request = false;
      this.transcode = false;
      this.settings = false;
      this.accounting = false;
      this.onBoardinguser = false;
      if (this.permissionArray.length > 0) {
        this.permissionArray.forEach((el) => {
          if (el.pernissionName === "Dashboard" && el.view) {
            this.dashboard = true;
            // this.router.navigate(['/onboarding-module/onboarding'])
          }
          if (el.pernissionName === "Customers" && el.view) {
            this.customers = true;
          }
          if (el.pernissionName === "Email Templates" && el.view) {
            this.emailTemp = true;
          }
          if (el.pernissionName === "Subscription" && el.view) {
            this.subscription = true;
          }
          // if (el.pernissionName === 'Expense' && !el.view) {
          //   this.expense = false
          // }
          // if (el.pernissionName === 'Revenue' && !el.view) {
          //   this.revenue = false
          // }
          if (el.pernissionName === "Accounting" && el.view) {
            this.accounting = true;
          }
          if (el.pernissionName === "Onboarding Users" && el.view) {
            this.onBoardinguser = true;
          }

          if (el.pernissionName === "Total Spent" && el.view) {
            this.totalSpent = true;
          }
          if (el.pernissionName === "Summary" && el.view) {
            this.sumarry = true;
          }
          if (el.pernissionName === "Security Group" && el.view) {
            this.securityGroup = true;
          }
          if (el.pernissionName === "Upload Stock" && el.view) {
            this.uploadStock = true;
          }
          if (el.pernissionName === "Request" && el.view) {
            this.request = true;
          }
          if (el.pernissionName === "Transcoding" && el.view) {
            this.transcode = true;
          }
          if (el.pernissionName === "Settings" && el.view) {
            this.settings = true;
          }
        });
      }
      setTimeout(() => {
        this.showMenu = true;
      }, 100);

      // this.securityGroup = response.data.groupName;
      // for (const selPermission of response.data.permission) {
      //   for (const permission of this.permissionList) {
      //     if (permission.id === selPermission.permissionId) {
      //       permission.isView = selPermission.view;
      //       break;
      //     }
      //   }
      // }
    });
  }

  /**
   * To get subscription data
   */
  getProgressDataData() {
    this.subscriptonProgressData.add(
      this.sharedService.updateProgressBar$.subscribe((data: any) => {
        this.progressInfos = data;
        if (this.progressInfos.length === 0) {
          this.showUpdatePopup = false;
        }
        this.progressInfos.forEach((el, index) => {
          if (el.value > 0) {
            this.showUpdatePopup = true;
          }
        });
      })
    );
  }

  ngOnDestroy() {
    if (this.subscriptonData) {
      this.subscriptonData.unsubscribe();
    }
    if (this.subscriptonProgressData) {
      this.subscriptonProgressData.unsubscribe();
    }
  }

  /**
   * To close the sidebar
   */
  closeSidebar() {
    this.progressInfos = localStorage.getItem("progress")
      ? JSON.parse(localStorage.getItem("progress"))
      : "";
    // this.progressInfos = progressCount
    // if(parseInt(progressCount) < 100 && parseInt(progressCount) > 0){
    //   this.stopInterval
    //   this.stopInterval = setInterval(() => {
    // this.updateProgressValue(this.progressInfos)
    //   }, 500);
    // for (let i = 0; i < this.progressInfos.length; i++) {
    //   if(this.progressInfos[i].value < 100 && this.progressInfos[i].value > 0){
    //     this.showUpdatePopup = true;
    //     this.stopInterval
    //     this.stopInterval = setInterval(() => {
    //     this.updateProgressValue(i,this.progressInfos[i]);
    //   }, 500);
    //   }
    // }
    // }
    // if(parseInt(progressCount)){
    //   this.progress = parseInt(progressCount);
    //   console.log(this.progress);
    // }
    if (window.innerWidth <= 767) {
      this.closeSidebarPanel.emit((this.panelState = !this.panelState));
    }
  }

  /**
   * Click handler
   * @param event Click event
   */
  clickHandler(event: any) {
    if (window.innerWidth <= 767) {
      if (!event.target.closest(".hamburger-icon, .app-sidebar")) {
        // this.closeSidebar();
      }
    }
  }
}
